import { get, orderBy } from 'lodash';
import { Session } from 'next-auth';

import { PAYMENT_COLLECTION_METHODS, PLAN_TIER_PAID, SORTING, USER_ROLES } from '@/lib/constants';
import {
  OrgsPublicTableInterface,
  OrgsUsersPublicTableInterface,
  UsersPublicTableInterface,
} from '@/types/database-types';
import { PermissionFn } from '@/utils/permissions/types';

export const isAuthenticated: PermissionFn = ({ session }) => {
  return Boolean(session);
};

export const sortOrgsByActiveSubscription = (orgs: OrgsPublicTableInterface[]): OrgsPublicTableInterface[] => {
  return orgs ? orderBy(orgs, ['subscription.status'], [SORTING.ASC]) : orgs;
};

export const getActiveUserOrgs = (user: Session['user'] | undefined) => {
  if (!user?.orgs?.length) return null;
  return user.orgs?.filter((org) => !!org.subscription) || null;
};

export const getUserOrgs = (user: Session['user']) => {
  return user?.orgs || null;
};

export const getUserOrg = (user: Session['user'], searchAllOrgs = false): OrgsPublicTableInterface | null => {
  const currentOrgId = user?.settings?.currentOrg?.value;
  const userOrgs = searchAllOrgs ? getUserOrgs(user) : getActiveUserOrgs(user);
  if (!userOrgs) return null;

  if (currentOrgId) {
    // If a stored OrgID exists, find it in the org list and return
    const org = userOrgs?.find((o) => o.id === currentOrgId);
    if (org) return org;
  }
  // Use first active org in list as default
  return sortOrgsByActiveSubscription(userOrgs)?.[0] || null;
};

export const isSuperAdmin: PermissionFn = ({ session }) => {
  // Still check that the userRole is admin to restrict non-admin users from accessing super admin features
  return Boolean(session?.entitlements?.superAdmin) && getUserIsAdmin(session?.user);
};

export const getUserRole = (user: Session['user']) => {
  const org = getUserOrg(user);
  return org?.userRole;
};

export const getRoleIsAdmin = (userRole: OrgsUsersPublicTableInterface['userRole']) => userRole === USER_ROLES.ADMIN;

export const getUserIsAdmin = (user: Session['user'] | undefined) => {
  if (!user) return false;

  const userRole = getUserRole(user);
  if (!userRole) return false;
  return getRoleIsAdmin(userRole);
};

export const getOrgAdmins = (users: UsersPublicTableInterface[]): UsersPublicTableInterface[] => {
  return users.filter((user) => (user.userRole ? getRoleIsAdmin(user.userRole) : false));
};

export const getDoesUserBelongToOrg = (user: Session['user'] | undefined, orgId: string): boolean => {
  if (!user) return false;
  return user.orgs?.some((org) => org.id === orgId) || false;
};

export const getUserIsOAuthUser = (user: Session['user']) => get(user, 'accountType') === 'oauth';

export const getUserIsOnFreeTier = (subscription: Session['subscription']): boolean =>
  subscription?.tier !== PLAN_TIER_PAID;

export const getUserIsOnPaidTier = (subscription: Session['subscription']): boolean =>
  subscription?.tier === PLAN_TIER_PAID;

export const hasInvoicePayment: PermissionFn = ({ session }): boolean => {
  if (!session || !session.subscription) return false;

  return session.subscription.collectionMethod === PAYMENT_COLLECTION_METHODS.SEND_INVOICE;
};

export const canAccessInvoices: PermissionFn = ({ session }): boolean => {
  if (!session || !session.subscription) return false;

  const isAdmin = getUserIsAdmin(session.user);

  const invoicePayment = hasInvoicePayment({ session });

  return isAdmin && !invoicePayment;
};
