/**
 * Opens a dialog to welcome new users
 *
 * Moved into a separate component to avoid duplcation of
 * this code in both app-layout and page-layout
 */

import Cookies from 'js-cookie';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  COOKIE_ONBOARDED,
  NEXT_AUTH_STATUSES,
  ROUTING_SEARCH_URL,
  SCHEDULE_SEARCH_URL,
  SITE_NAME,
  TRACKING_URL,
} from '@/lib/constants';
import { dialogState } from '@/state/dialog';
import { trackUser } from '@/utils/tracking';

const DialogWelcome = () => {
  const { data: session, status } = useSession();

  const pathname = usePathname();

  const setDialog = useSetRecoilState(dialogState);

  const hasBeenOnboarded = Cookies.get(COOKIE_ONBOARDED);

  // Check if the current page path starts with "/auth" as we don't want
  // to show the welcome dialog until they have been onboarded
  const isAuthPage = pathname?.startsWith('/auth');

  const isNewUser = session?.user?.isNewUser;

  const handleCloseWelcomeMessage = () => {
    Cookies.set(COOKIE_ONBOARDED, 'true', { expires: 7 });
  };

  const shouldShowWelcomeDialog =
    isNewUser && status === NEXT_AUTH_STATUSES.AUTHENTICATED && !hasBeenOnboarded && !isAuthPage;

  useEffect(() => {
    if (shouldShowWelcomeDialog) {
      // Log event that user was logged in successully
      trackUser.event('Create New Account (Success)', {
        location: 'Welcome Dialog',
      });
      // GA conversion event
      trackUser.conversion({ category: 'SignUp', action: 'NewAccountCreated' });

      // Wait a moment to show the welcome dialog to avoid flicker
      setTimeout(
        () =>
          setDialog({
            isOpen: true,
            title: `Welcome to ${SITE_NAME}`,
            body: `<p>At Fluent Cargo, our mission is to create the world&apos;s most comprehensive shipment planning tools for those in global trade.</p>`,
            hasLogo: true,
            footnote:
              'Please be aware that Fluent Cargo is a shipment planning and tracking tool and we do not offer freight and cargo booking services',
            buttons: [
              {
                text: 'Route search',
                variant: 'outline',
                href: ROUTING_SEARCH_URL,
                onClick: () => {
                  handleCloseWelcomeMessage();
                  setDialog({ isOpen: false });
                },
                iconRight: 'arrow-right',
              },
              {
                text: 'Schedules',
                variant: 'outline',
                href: SCHEDULE_SEARCH_URL,
                onClick: () => {
                  handleCloseWelcomeMessage();
                  setDialog({ isOpen: false });
                },
                iconRight: 'arrow-right',
              },
              {
                text: 'Track a shipment',
                variant: 'outline',
                href: TRACKING_URL,
                onClick: () => {
                  handleCloseWelcomeMessage();
                  setDialog({ isOpen: false });
                },
                iconRight: 'arrow-right',
              },
            ],
            onClose: () => handleCloseWelcomeMessage(),
            maxWidth: '600px',
          }),
        600,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewUser, status, hasBeenOnboarded, isAuthPage]);

  return <div />;
};

export { DialogWelcome };
