import {
  add,
  addHours,
  addMonths,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  endOfDay,
  format,
  isBefore,
  isSameSecond,
  parse,
  parseISO,
  startOfDay,
  sub,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DateRange } from 'react-day-picker';

import {
  DATE_FORMAT,
  DATE_MONTH_FORMAT,
  DATETIME_FORMAT,
  FULL_DATETIME_FORMAT,
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  KEY_DATE_FORMAT,
  SHIPMENT_DATE_TIME,
  TIME_FORMAT,
} from '@/lib/constants';

const formatDate = (timeInUtc: number | Date): string => {
  return format(timeInUtc, 'yyyy/MM/dd');
};

const formatDateTime = (timeInUtc: number | Date): string => {
  return format(timeInUtc, 'yyyy/MM/dd HH:mm:ss');
};

const formatIsoDate = (dateTime: Date, dateFormat = ISO_DATE_FORMAT): string => {
  return format(dateTime, dateFormat);
};

const formatTime = (dateTime: Date): string => {
  return format(dateTime, TIME_FORMAT);
};

const formatDateRange = (range: string[]): string => {
  const formatted = range.map((date) => formatIsoDate(new Date(date), DATE_MONTH_FORMAT));
  const year = formatIsoDate(new Date(range[range.length - 1]), 'yyyy');
  return `${formatted.join(' → ')} ${year}`;
};

/**
 * Formats a time string to shorter string
 */
const formatTimeString = (timeString: string): string => {
  const timeComponents = timeString.split(':');
  return timeComponents.slice(0, 2).join(':');
};

const formatDateForUrl = (dateTime: Date): string => {
  return format(dateTime, ISO_DATE_FORMAT);
};

const formatDateTimeForUrl = (dateTime: Date): string => {
  return format(dateTime, ISO_DATE_TIME_FORMAT);
};

const formatDateForCacheKey = (dateTime: Date): string => {
  return format(dateTime, KEY_DATE_FORMAT);
};

const getStartDate = (date: Date, departHour: number) => {
  return addHours(startOfDay(date), departHour);
};

const getEndDate = (date: Date, arriveHour: number): Date => {
  if (arriveHour !== 24) {
    return addHours(startOfDay(date), arriveHour);
  }
  return endOfDay(date);
};

const getToday = () => startOfDay(new Date());

const formatDateRangeForUrl = ({
  dateRange,
  departHour = 0,
  arriveHour = 24,
}: {
  dateRange: DateRange;
  departHour?: number;
  arriveHour?: number;
}): string => {
  const finalStartDate = dateRange.from ? getStartDate(dateRange.from, departHour) : undefined;
  const finalEndDate = dateRange.to ? getEndDate(dateRange.to, arriveHour) : undefined;

  return JSON.stringify({
    start: finalStartDate ? format(finalStartDate, ISO_DATE_TIME_FORMAT) : undefined,
    end: finalEndDate ? format(finalEndDate, ISO_DATE_TIME_FORMAT) : undefined,
  });
};

// Formats a user friendly DD MM YYYY Date format
const formatDateForUser = (dateTime: Date | string, dateFormat = DATE_FORMAT): string => {
  const date = dateTime instanceof Date ? dateTime : new Date(dateTime);
  return format(date, dateFormat);
};

// Formats a user friendly DD MM YYYY HH:MM Date format
const formatDateTimeForUser = (dateTime: Date | string, dateFormat = FULL_DATETIME_FORMAT): string => {
  const date = dateTime instanceof Date ? dateTime : new Date(dateTime);
  return format(date, dateFormat);
};

/**
 * Returns Day different between to dates
 */
const getDayDifferenceBetweenDates = (date1: string | Date, date2: string | Date): number => {
  const dateRight = new Date(date1);
  const dateLeft = new Date(date2);
  return differenceInCalendarDays(dateLeft, dateRight);
};

/**
 * Localtime helpers (takes a timezone and ensures the date/time is printed in that timezone)
 */
const formatToLocationDateTime = (dateTime: Date, timezone: string, dateFormat = DATETIME_FORMAT): string => {
  return formatInTimeZone(dateTime, timezone, dateFormat);
};

/**
 * Takes a two dateTimes and displays them as two times in location time with a date break indicator
 * i.e Time → Time +1
 */
const getScheduleDepartArriveString = (
  departureDateTime: Date,
  arrivalDateTime: Date,
  departureTimezone: string,
  arrivalTimezone: string,
  daysOnly: boolean,
): string => {
  const localDepartureDateTime = daysOnly
    ? formatToLocationDateTime(departureDateTime, departureTimezone, DATE_MONTH_FORMAT)
    : formatToLocationDateTime(departureDateTime, departureTimezone, TIME_FORMAT);
  const localArrivalDateTime = daysOnly
    ? formatToLocationDateTime(arrivalDateTime, arrivalTimezone, DATE_MONTH_FORMAT)
    : formatToLocationDateTime(arrivalDateTime, arrivalTimezone, TIME_FORMAT);

  return `${localDepartureDateTime} → ${localArrivalDateTime}`;
};

/**
 * Difference in days between two dates (in locale)
 */
const getDayDifferenceBetweenLocalDates = (
  departureDateTime: Date,
  arrivalDateTime: Date,
  departureTimezone: string,
  arrivalTimezone: string,
): number => {
  const localDepartureDate = formatToLocationDateTime(departureDateTime, departureTimezone, DATE_FORMAT);
  const localArrivalDate = formatToLocationDateTime(arrivalDateTime, arrivalTimezone, DATE_FORMAT);

  return getDayDifferenceBetweenDates(localDepartureDate, localArrivalDate);
};

/**
 * Takes a ISO8601 week string (e.g. 2022-W10) and returns a
 * formatted string (e.g. 2022-03-07)
 */
const getISOStringFromWeek = (date: string, dateformat = ISO_DATE_FORMAT) => {
  if (!date) return null;

  const parsedDate = parseISO(date);
  const isoString = format(parsedDate, dateformat);

  return isoString;
};

/**
 * Adds a delay to a Date
 */
const addDelayToDate = (date: Date, delayInDays: number): Date => {
  if (date && delayInDays && delayInDays > 0) {
    return add(date, { days: delayInDays });
  }
  return date;
};

/**
 * Gets the next future Occurrence of a date
 * @param dateString - datestring or Date
 * @param compareDate - optional date (default Today)
 * @returns date in the future
 */
const getNextOccurrenceOfDate = (dateString: string | Date, compareDate = ''): string => {
  const compare = compareDate ? new Date(compareDate) : new Date();
  const originalDate = new Date(dateString);
  // Get difference between now and original date
  const monthsBetween = differenceInCalendarMonths(originalDate, compare);
  // Add difference to get date close to today
  const newDate = addMonths(originalDate, Math.abs(monthsBetween));
  // Check if newDate falls before today
  const isBeforeToday = isBefore(newDate, compare);
  // If it doesn't add another month
  const nextOccurrence = isBeforeToday ? addMonths(newDate, 1) : newDate;

  return nextOccurrence.toISOString();
};

/**
 * Gets the date 1 month before the given date
 * @param dateString - datestring or Date
 * @returns date as string
 */
const getPreviousMonthDate = (dateString: string | Date): string => {
  const originalDate = new Date(dateString);
  // Track back one month
  const newDate = addMonths(originalDate, -1);
  return newDate.toISOString();
};

/**
 * Returns a date that is X days in the future
 */
const getCurrentDatePlusXDays = (offsetDays: number): string => {
  return add(endOfDay(new Date()), { days: offsetDays }).toISOString();
};

/**
 * Returns a date that is X days in the past
 */
const getCurrentDateWithMinusXDays = (offsetDays: number): Date => {
  return sub(startOfDay(new Date()), { days: offsetDays });
};

const formatDateStringToIso = (value: string | null | undefined): string | null => {
  if (!value) return null;

  const parsedDate = tryParseDate(value);
  if (!parsedDate) return null;

  const offset = parsedDate.getTimezoneOffset();
  parsedDate.setMinutes(parsedDate.getMinutes() - offset);

  return parsedDate.toISOString().replace('Z', '').replace('.000', '');
};

const isSameDateString = (a?: string, b?: string) => {
  if (!a && !b) return true;
  if (!a || !b) return false;
  return isSameSecond(parseISO(a), parseISO(b));
};

const tryParseDate = (value?: string | null): Date | null => {
  if (!value) return null;

  // In some cases, we will get a date string formatted by cargoes in an odd
  // way. This works fine in chrome, but breaks in safari, so we test for it
  // specifically
  const cargoesDateFormat = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} (AM|PM)$/;

  if (cargoesDateFormat.test(value)) {
    return parse(value, SHIPMENT_DATE_TIME, new Date());
  }

  const parsedDate = Date.parse(value);

  if (Number.isNaN(parsedDate)) {
    return null;
  }

  // value is a valid date - create a new date instance
  // eslint-disable-next-line custom-rules/no-new-date
  return new Date(value);
};

export {
  addDelayToDate,
  formatDate,
  formatDateForCacheKey,
  formatDateForUrl,
  formatDateForUser,
  formatDateRange,
  formatDateRangeForUrl,
  formatDateStringToIso,
  formatDateTime,
  formatDateTimeForUrl,
  formatDateTimeForUser,
  formatIsoDate,
  formatTime,
  formatTimeString,
  formatToLocationDateTime,
  getCurrentDatePlusXDays,
  getCurrentDateWithMinusXDays,
  getDayDifferenceBetweenDates,
  getDayDifferenceBetweenLocalDates,
  getISOStringFromWeek,
  getNextOccurrenceOfDate,
  getPreviousMonthDate,
  getScheduleDepartArriveString,
  getToday,
  isSameDateString,
  tryParseDate,
};
