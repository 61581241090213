/**
 * Custom hook to identify if a user just logged in and log some identity data
 */

import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

import { NEXT_AUTH_STATUSES } from '@/lib/constants';
import { getUserHasCancelledSubscription, getUserHasSubscription, getUserSubscriptionTier } from '@/utils/auth';
import { trackUser } from '@/utils/tracking';

export const useIdentifyUser = () => {
  const { status, data: session } = useSession();

  useEffect(() => {
    if (status === NEXT_AUTH_STATUSES.AUTHENTICATED) {
      const isActiveSubscriber = getUserHasSubscription(session?.subscription);
      const subscriptionType = session?.subscription.tier;
      const isCancelledSubscriber = getUserHasCancelledSubscription(session?.subscription);
      const subscriptionTier = getUserSubscriptionTier(session?.subscription);
      const isFreightForwarder = !!session?.user.isFreightForwarder;
      const isBusinessUser = session?.user.usagePurpose === 'business';

      trackUser.setUserIdentity(session!.user.id, {
        email: session!.user.email,
        isActiveSubscriber,
        subscriptionType,
        subscriptionTier,
        isCancelledSubscriber,
        isFreightForwarder,
        isBusinessUser,
      });
    }
  }, [status, session]);
};
