export const formatPrice = (unit: number, currency: string, base = 100): string => {
  if (Number.isFinite(unit)) {
    // Base 100 = cents
    // Base 1 = dollars
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
    }).format((unit || 0) / base);
  }
  return '0.00';
};
