/**
 * Creates a URL for the mail carriers admin API
 * @param pageIndex - current page index
 * @param perPage - number of items per page
 * @param search - optional search query string
 * @returns URL for the mail carriers API
 */
export const getMailCarriersUrl = (pageIndex?: number, perPage?: number, search?: string): string => {
  const params = new URLSearchParams();
  if (pageIndex) params.set('pageIndex', String(pageIndex));
  if (perPage) params.set('perPage', String(perPage));
  if (search) params.set('search', search);

  const url = '/api/mail-carriers';
  return params.toString() ? `${url}?${params.toString()}` : url;
};

/**
 * Creates a URL for syncing mail carriers
 * @returns URL for the mail carriers sync API
 */
export const getSyncMailCarriersUrl = (): string => {
  return '/api/mail-carriers/admin/sync';
};

/**
 * Creates a URL for updating a mail carrier's image
 * @param id - mail carrier ID
 * @returns URL for the mail carrier update API
 */
export const updateMailCarrierImage = (id: string): string => {
  return `/api/mail-carriers/admin/${id}`;
};
