import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import * as React from 'react';

import { Link } from '@/components/link';
import { LoadingIndicator } from '@/components/ui/loading/indicator';
import { EventTrackingInterface } from '@/types';
import { LinkInterface } from '@/types/block-types';
import { FieldErrors } from '@/types/form-types';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import { ButtonContentLayout } from './button-content-layout';

export interface ButtonProps extends LinkInterface, React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  children?: string | React.ReactNode | React.ReactNode[];
  href?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  target?: '_self' | '_blank' | '_parent' | '_top';
  variant?:
    | 'primaryLight'
    | 'primaryLightOutline'
    | 'primaryDark'
    | 'primaryDarkOutline'
    | 'secondaryLight'
    | 'secondaryDark'
    | 'error'
    | 'link'
    | 'linkBlue'
    | 'ghost'
    | 'grey'
    | 'greyLight'
    | 'lightInput'
    | 'darkInput'
    | 'outline'
    | 'white';
  rel?: string;
  iconLeft?: React.ReactNode | string;
  iconRight?: React.ReactNode | string;
  iconAnimX?: boolean;
  iconAnimY?: boolean;
  trackingData?: EventTrackingInterface;
  className?: string;
  disabled?: boolean;
  errors?: FieldErrors;
  type?: 'button' | 'submit' | 'reset';
  as?: React.ElementType;
  truncateText?: boolean;
  isRounded?: boolean;
}

// An HTML tag or a different React component can be rendered by default
const defaultElement = Link;

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      variant = 'primaryLight',
      size = 'medium',
      children,
      isDisabled,
      isLoading,
      href,
      onClick,
      target,
      iconLeft,
      iconRight,
      iconAnimX,
      iconAnimY,
      errors,
      trackingData,
      className,
      disabled,
      as,
      truncateText,
      isRounded,
      ...props
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
  ): JSX.Element => {
    // The `as` prop may be overridden by the passed props
    const { base, childrenWrapper, loadingIndicator } = buttonStyles({
      variant: !isEmpty(errors) ? 'error' : variant,
      size,
      isLoading,
      iconAnimX,
      iconAnimY,
      isDisabled: isDisabled || disabled,
      isRounded,
    });

    // If there is no href defined, assume its a button not a Link
    const Element = as || (!href ? 'button' : defaultElement);

    // Handle the click event and trigger tracking events if supplied
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      if (onClick) onClick(e);
      // Event tracking
      if (trackingData?.action) {
        trackUser.event('ButtonClick', trackingData); // Posthog
        trackUser.conversion(trackingData); // GA
      }
    };

    return (
      <Element
        href={href}
        onClick={handleClick}
        rel={target ? 'noreferrer noopener' : null}
        target={target}
        disabled={isDisabled} // Doesn't work on <a> tags so we add an is-disabled class
        className={base({ className })}
        ref={ref}
        {...props}
      >
        <ButtonContentLayout
          iconLeft={iconLeft}
          iconRight={iconRight}
          truncateText={truncateText}
          className={variant === 'lightInput' || variant === 'darkInput' ? 'w-full' : undefined}
        >
          <>
            <span className={childrenWrapper()}>{children}</span>
            <AnimatePresence>
              {isLoading && (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <LoadingIndicator darkTheme={isDisabled} className={loadingIndicator()} />
                </motion.span>
              )}
            </AnimatePresence>
          </>
        </ButtonContentLayout>
      </Element>
    );
  },
);

const buttonStyles = tv({
  slots: {
    base: 'relative inline-flex cursor-pointer items-center justify-center whitespace-nowrap rounded-md text-center font-medium !leading-none transition-all duration-200 ease-in-out',
    childrenWrapper: 'opacity-100 transition-opacity duration-300 ease-in-out',
    loadingIndicator: 'loading-indicator absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
  },
  variants: {
    variant: {
      primaryLight:
        'border border-solid border-lightBlue-500 bg-lightBlue-500 text-white hover:border-lightBlue-600 hover:bg-lightBlue-600 [&.is-disabled]:pointer-events-none [&.is-disabled]:border-grey-200 [&.is-disabled]:bg-grey-200 [&.is-disabled]:!text-grey-600',
      primaryLightOutline:
        'border border-solid border-lightBlue-500 bg-white text-lightBlue-500 hover:border-lightBlue-600 hover:bg-lightBlue-600 hover:text-white [&.is-disabled]:pointer-events-none [&.is-disabled]:border-grey-200 [&.is-disabled]:bg-grey-200 [&.is-disabled]:!text-grey-600',
      primaryDark:
        'border border-solid border-blue-500 bg-blue-500 text-white hover:border-blue-600 hover:bg-blue-600 [&.is-disabled]:pointer-events-none [&.is-disabled]:border-grey-200 [&.is-disabled]:bg-grey-200 [&.is-disabled]:!text-grey-600',
      primaryDarkOutline:
        'border border-solid border-blue-500 bg-white text-blue-600 hover:border-blue-600 hover:bg-blue-600 hover:text-white [&.is-disabled]:pointer-events-none [&.is-disabled]:border-grey-200 [&.is-disabled]:bg-grey-200 [&.is-disabled]:!text-grey-600',
      secondaryLight:
        'border border-solid border-blue-50 bg-blue-50 text-blue-800 hover:border-blue-200 hover:bg-blue-200 [&.is-disabled]:pointer-events-none [&.is-disabled]:border-grey-200 [&.is-disabled]:bg-grey-200 [&.is-disabled]:!text-grey-600',
      secondaryDark:
        'border border-solid border-blue-700 bg-blue-700 text-white hover:border-blue-900 hover:bg-blue-800 [&.is-disabled]:pointer-events-none [&.is-disabled]:border-grey-200 [&.is-disabled]:bg-grey-800 [&.is-disabled]:!text-grey-600',
      error:
        'border border-solid border-error bg-error text-white hover:border-errorDark hover:bg-errorDark [&.is-disabled]:pointer-events-none [&.is-disabled]:border-grey-200 [&.is-disabled]:bg-grey-200 [&.is-disabled]:!text-grey-600',
      link: '[&>span>span>span]:border-b-current rounded-none border-none !p-0 font-normal text-text-primary !transition-none [&.is-disabled]:pointer-events-none [&.is-disabled]:text-grey-400 [&>span>span>span]:border-b hover:[&>span>span>span]:border-b-transparent',
      linkBlue:
        'rounded-none border-none !p-0 font-normal text-lightBlue-500 !transition-none [&.is-disabled>span>span>span]:border-b-grey-300 [&.is-disabled]:pointer-events-none [&.is-disabled]:text-grey-400 [&>span>span>span]:border-b [&>span>span>span]:border-b-lightBlue-500/30 hover:[&>span>span>span]:border-b-transparent',
      ghost:
        'border border-solid border-transparent bg-transparent text-grey-900 hover:bg-lightBlue-50 [&.is-disabled]:pointer-events-none [&.is-disabled]:text-grey-400',
      grey: 'border border-solid border-grey-300 bg-grey-300 text-grey-700 hover:border-grey-500 hover:bg-grey-400 [&.is-disabled]:bg-grey-200',
      greyLight:
        'border border-solid border-grey-300 bg-grey-200 text-grey-700 hover:border-grey-500 [&.is-disabled]:bg-grey-200',
      lightInput:
        'w-full border border-solid border-grey-300 bg-white !text-baseSm font-normal text-text-primary disabled:cursor-not-allowed disabled:opacity-50 aria-expanded:border-grey-500 sm:!px-3.5 hover:[&:not(:disabled)]:border-grey-500',
      darkInput:
        'w-full border border-solid border-grey-900 bg-[#3F4143] !text-baseSm font-normal text-grey-200 disabled:cursor-not-allowed disabled:opacity-50 aria-expanded:border-grey-700 sm:!px-3.5 hover:[&:not(:disabled)]:border-grey-700',
      outline:
        'border border-solid border-grey-350 bg-white text-blue-800 hover:border-blue-600 [&.is-disabled]:pointer-events-none [&.is-disabled]:border-transparent [&.is-disabled]:bg-grey-50 [&.is-disabled]:!text-grey-500',
      white:
        'border border-solid border-white bg-white text-blue-500 hover:border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white [&.is-disabled]:pointer-events-none [&.is-disabled]:border-grey-200 [&.is-disabled]:bg-grey-200 [&.is-disabled]:!text-grey-600',
    },
    size: {
      large: 'px-6 py-3.5 text-baseLg',
      medium: 'max-h-[var(--input-height)] px-4 py-3 text-[15px] sm:px-5',
      small: 'max-h-[30px] px-3 py-sm text-sm',
      xsmall: 'max-h-[24px] px-2 py-1.5 text-sm',
    },
    isRounded: {
      true: 'rounded-full',
    },
    isLoading: {
      true: {
        childrenWrapper: 'opacity-0',
      },
    },
    isDisabled: { true: 'is-disabled' },
    iconAnimX: {
      true: '[&_svg]:hover:animate-bounce-x',
    },
    iconAnimY: {
      true: '[&_svg]:hover:animate-bounce-y',
    },
  },
  compoundVariants: [
    {
      size: 'large',
      isRounded: true,
      class: '!px-8 max-md:!py-3 max-md:text-base',
    },
  ],
});

Button.displayName = 'Button';

export { Button };
