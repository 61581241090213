import '../styles/globals.css';

import type { AppProps } from 'next/app';
import Script from 'next/script';
import { SessionProvider } from 'next-auth/react';
import { LinkedInInsightTag } from 'nextjs-linkedin-insight-tag';
import type { ReactElement } from 'react';
import { RecoilRoot } from 'recoil';

import { ClientPosthogContext } from '@/components/client-wrappers/posthog-context';
import { Layout } from '@/components/layouts';
import { UiComponentsProvider } from '@/components/ui-components-provider';
import { AnalyticsContextProvider } from '@/lib/context/analytics';

const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

// App
const App = ({ Component, pageProps: { session, ...pageProps } }: AppProps): ReactElement => {
  return (
    <>
      <SessionProvider session={session}>
        <RecoilRoot>
          <UiComponentsProvider>
            <ClientPosthogContext>
              <AnalyticsContextProvider>
                <Layout>
                  <LinkedInInsightTag />
                  <Component {...pageProps} />
                </Layout>
              </AnalyticsContextProvider>
            </ClientPosthogContext>
          </UiComponentsProvider>
        </RecoilRoot>
      </SessionProvider>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
        `}
      </Script>
    </>
  );
};

export default App;
