'use client';

import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { useEffect } from 'react';

const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;

interface Props {
  children: React.ReactNode;
}

if (!POSTHOG_KEY) console.error('Missing NEXT_PUBLIC_POSTHOG_KEY in client/main/.env');

export const ClientPosthogContext = ({ children }: Props) => {
  const router = useRouter();

  useEffect(() => {
    // Initialize PostHog only once
    // eslint-disable-next-line no-underscore-dangle
    if (typeof window !== 'undefined' && POSTHOG_KEY && !posthog.__loaded) {
      posthog.init(POSTHOG_KEY, {
        api_host: 'https://app.posthog.com',
        autocapture: false,
        opt_in_site_apps: true,
        // Enable debug mode in development
        // loaded: (ph) => {
        //   if (!IS_PROD) ph.debug();
        // },
      });
    }

    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
